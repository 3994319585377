<style></style>
<script>
import {get_acsrf_token} from "@/methods";
import {mapActions, mapGetters} from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "AccountSettings",
  computed: {
    ...mapGetters([
      'getUILanguage',
    ]),
    ...mapActions([
      'setUILanguage',
    ]),
  },
  components: {
    Multiselect
  },
  created() {
    this.languageSelection.forEach((language) => {
      if (language.key === this.getUILanguage()) {
        this.language = language;
        return;
      }
    });
  },
  methods: {
    async changeUILanguage() {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          language: this.language.key
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/language`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if (response.ok) {
          await this.$swal({
            icon: 'success',
            title: this.$t('account.language.success')
          });
          this.$store.commit('setUILanguage', this.language.key);
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if (response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        await this.$swal({
          icon: 'error',
          title: this.$t('error.server.generic.message')
        });
      }
    },
  },
  data() {
    return {
      languageSelection: [
        {
          key: 'en',
          flag: 'gb',
          text: 'English'
        },
        {
          key: 'de',
          flag: 'de',
          text: 'Deutsch'
        },
        {
          key: 'fr',
          flag: 'fr',
          text: 'Français'
        },
        {
          key: 'es',
          flag: 'es',
          text: 'Español (España)'
        },
        {
          key: 'ru',
          flag: 'ru',
          text: 'Pусский (Россия)'
        },
        {
          key: 'pt',
          flag: 'pt',
          text: 'Português (Portugal)'
        },
        {
          key: 'pt-br',
          flag: 'br',
          text: 'Português (Brasil)'
        }
      ],
      language: null,
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-11">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <div role="tablist">
                        <!-- Account settings card. -->
                        <b-card no-body class="shadow-none">
                          <a v-b-toggle.settingsEntries class="text-dark" href="javascript: void(0);">
                            <b-card-header header-tag="header" role="tab">
                              <h3 class="m-0 d-inline-flex">
                                {{ $t('account.language.title') }}
                              </h3>
                              <div class="d-inline-flex ml-1 text-muted">
                                {{ $t('account.language.description') }}
                              </div>
                            </b-card-header>
                          </a>
                          <b-card-body>
                            <b-card-text>
                              <template>
                                <div class="row">
                                  <div class="col">
                                    <multiselect
                                        v-model="language"
                                        label="text"
                                        track-by="key"
                                        :options="languageSelection"
                                        :show-labels="false"
                                        :allow-empty="false"
                                        @input="changeUILanguage()"
                                    >
                                      <template
                                          slot="singleLabel"
                                          slot-scope="props"
                                      >
                                        <div>
                                          <flag
                                              class="option__image"
                                              :iso="props.option.flag"
                                              :squared="false"
                                          />
                                          <span class="option__desc d-inline ml-2">
                                            <span class="option__title align-middle h4">
                                              {{ props.option.text }}
                                            </span>
                                          </span>
                                        </div>
                                      </template>
                                      <template
                                          slot="option"
                                          slot-scope="props"
                                      >
                                        <div>
                                          <flag
                                              class="option__image d-inline-block"
                                              :iso="props.option.flag"
                                              :squared="false"
                                          />
                                          <div class="option__desc d-inline ml-2">
                                            <span class="option__title align-middle h4">
                                              {{ props.option.text }}
                                            </span>
                                          </div>
                                        </div>
                                      </template>
                                    </multiselect>
                                    <h5 class="text-uppercase text-muted text-center mt-2">
                                      {{ $t('account.language.disclaimer') }}
                                    </h5>
                                  </div>
                                </div>
                              </template>
                            </b-card-text>
                          </b-card-body>
                        </b-card>
                        <!-- Account settings card end. -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
